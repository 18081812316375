import React from 'react';
import { optionGet } from 'faunctions';
import Itly from 'itly';
import NavLink from './NavLink';
import { MagentoMenuItem } from '../../../../types/Navigation';
import CartSvg from '../../../../icons/Cart.svg';
import LogoSvg from '../../../../icons/Logo.svg';
import './Navigation.css';
import { useSelector, useDispatch } from 'react-redux';
import { customerIsLoggedInSelector } from 'store/customer/selectors';
import { showIfOrElse } from 'helpers/conditionals';
import { openCustomerLoginModal, openSearchModal } from 'store/modal/actions';
import { cartItemsSelector, cartLoadingStateSelector } from 'store/cart/selectors';
import { Show } from 'components/Functional';
import { isFetching, isInitialized } from 'store/constants';
import { getLocalStorageCartInfo, hasAuthedCartInfo } from 'helpers/storage/cart';
import { SmallLoader } from 'components/AULoader/SmallLoader';
import { showIf } from 'component-library/helpers/conditionals';
import LogoutButton from 'components/LogoutButton';
import { Icon } from 'component-library';
import { trackSearchStarted } from 'analytics/itly/hooks/useTrackSearchStarted';
import MyAccountIcon from '../../../../icons/MyAccount.svg';
import {
  CartCountWrapper,
  LoginButton,
  LoginLoaderContainer,
  MyAccountIconStyled,
  StyledCart,
  StyledLogo
} from './styled';
import SvgContainer from 'icons/SvgContainer';
import {
  MY_ACCOUNT,
  ORDER_HISTORY,
  MY_PROJECTS,
  GALLERIES,
  REFER_A_FRIEND,
  SHARE_YOUR_STORY
} from 'constants/navigation';
import { getCurrentURL } from 'helpers/navigation';
import { photoBooksNavABTestItems } from 'components/Header/Navigation/Desktop/PhotoBooksNavABTest';
import { useSplitTreatment } from '../../../../hooks/splits/useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_PHOTO_BOOKS_NAVIGATION_DESKTOP,
  SPLIT_IO_FEATURE_FLAG_PHOTO_BOOKS_NAVIGATION_DESKTOP_COOKIE
} from 'constants/split';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';
import { DeviceSize, useWindowSize } from '../../../../hooks/useWindowSize';

export interface DesktopNavProps {
  menuItems: MagentoMenuItem[];
}

const DesktopNav = ({ menuItems }: DesktopNavProps): JSX.Element => {
  const cartItemCount = useSelector(cartItemsSelector).length;
  const cartLoadingState = useSelector(cartLoadingStateSelector);
  const isRequesting = isInitialized(cartLoadingState) || isFetching(cartLoadingState);
  const customerIsLoggedIn = useSelector(customerIsLoggedInSelector);
  const dispatch = useDispatch();
  const { deviceSize } = useWindowSize();

  const cartInfo = getLocalStorageCartInfo();

  const { treatmentStatus: showPhotoBooksNavDesktopSplit } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_PHOTO_BOOKS_NAVIGATION_DESKTOP,
    SPLIT_IO_FEATURE_FLAG_PHOTO_BOOKS_NAVIGATION_DESKTOP_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );

  if (showPhotoBooksNavDesktopSplit && deviceSize !== DeviceSize.SMALL) {
    menuItems.forEach((item) => {
      if (item.node.name === 'Photo Books') {
        item.node.items = photoBooksNavABTestItems;
      }
    });
  }

  // Once we start to switch over to the new gatsby-ified account pages we will use `pathToGatsbySite`
  const myAccountLinks = [
    {
      name: MY_ACCOUNT.name,
      path: MY_ACCOUNT.url,
      isGatsbyPage: true,
      children: [
        {
          name: MY_PROJECTS.name,
          path: MY_PROJECTS.url,
          isGatsbyPage: true
        },
        {
          name: GALLERIES.name,
          path: GALLERIES.url,
          isGatsbyPage: true
        },
        {
          name: ORDER_HISTORY.name,
          path: ORDER_HISTORY.url,
          isGatsbyPage: true
        },
        {
          name: REFER_A_FRIEND.name,
          path: REFER_A_FRIEND.url,
          isGatsbyPage: true
        },
        {
          name: SHARE_YOUR_STORY.name,
          path: SHARE_YOUR_STORY.url,
          isGatsbyPage: false
        }
      ]
    }
  ];

  const handleSearchClick = () => {
    trackSearchStarted();
    dispatch(openSearchModal({ menuItems }));
  };

  const showLoginModal = (path) => dispatch(openCustomerLoginModal({ redirect: path }));

  const handleLoginClick = () => {
    Itly.loginStarted({ login_type: 'Nav' });
    showLoginModal(getCurrentURL());
  };

  return (
    <div className="DesktopNav__Wrapper">
      <NavLink name="Main Logo" path="/">
        <StyledLogo>{SvgContainer(LogoSvg)}</StyledLogo>
      </NavLink>
      <div className="DesktopNav__Links">
        {menuItems.map((mi: MagentoMenuItem, i: number) => {
          const name = optionGet('node.name')(mi).getOrElse('');
          const path = optionGet('node.path')(mi).getOrElse('');
          const isGatsbyPage = optionGet('node.isGatsbyPage')(mi).getOrElse(false);
          const dropdownMenuItems = optionGet('node.items')(mi).getOrElse([]);

          return (
            <NavLink
              key={i}
              name={name}
              path={path}
              dropdownMenuItems={dropdownMenuItems}
              isGatsbyPage={isGatsbyPage}
            />
          );
        })}
      </div>
      <div className="DesktopNav__AccountLinks">
        <a onClick={handleSearchClick}>
          <Icon icon="magnifyingGlass" size="small"/>
        </a>
        {showIfOrElse(customerIsLoggedIn || hasAuthedCartInfo(cartInfo))(
          <span>
            <NavLink
              name={MY_ACCOUNT.name}
              path={MY_ACCOUNT.url}
              dropdownMenuItems={myAccountLinks}
              leftDropdownChildren={<LogoutButton id={'Logout'} className={'DesktopNav__SubMenuLink'}/>}
            >
              <MyAccountIconStyled>{SvgContainer(MyAccountIcon)}</MyAccountIconStyled>
            </NavLink>
          </span>
        )(
          <>
            <LoginButton onClick={handleLoginClick}/>
            {showIf(
              <LoginLoaderContainer>
                <SmallLoader isLoading={true}/>
              </LoginLoaderContainer>
            )(isRequesting)}
          </>
        )}
        <NavLink name="Cart" path="/checkout/cart">
          <CartCountWrapper>
            <StyledCart>{SvgContainer(CartSvg)}</StyledCart>
            <Show when={!!cartItemCount}>
              <div
                style={{
                  position: 'relative',
                  left: '0.1rem',
                  top: '0.1rem',
                  color: '#f15223'
                }}
              >
                {cartItemCount}
              </div>
            </Show>
          </CartCountWrapper>
        </NavLink>
      </div>
    </div>
  );
};

export default DesktopNav;
