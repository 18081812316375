import React from 'react';
import { mediaQuery } from 'component-library';
import styled from 'styled-components';

import { Product } from 'types/Product';
import { StyledH3 } from './typography';
import ProductBlock from './ProductBlock';
import { getGatsbySiteUrl } from 'helpers/env';

const RelatedProductsContainer = styled.div`
  margin: auto;
  max-width: 1180px;
  padding: 1rem;
`;

const ProductBlockContainer = styled.div`
  display: flex;
  margin: 0 -8px;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
    margin: 8px;
    flex-basis: calc(50% - 16px);
    max-width: calc(50% - 16px);

    ${mediaQuery.medium`
      flex-basis: calc(33% - 16px);
      max-width: calc(33% - 16px);
    `};

    ${mediaQuery.large`
      flex-basis: calc(20% - 16px);
      max-width: calc(20% - 16px);
    `};
  }

  img {
    max-width: 100%;
  }
`;

const RelatedProductsTitle = styled(StyledH3)`
  text-align: center;
`;

interface RelatedProductsProps {
  products: Product[];
}

/*
 * This component duplicates some of the logic in src/components/RelatedProducts/CartUpsell.tsx.
 * We would prefer to consolidate the two of them, which will involve some discussion with Design.
 */
export function RelatedProducts(props: RelatedProductsProps) {
  const { products } = props;
  if (!products.length) {
    return null;
  }
  return (
    <RelatedProductsContainer>
      <RelatedProductsTitle secondary>You May Also Like</RelatedProductsTitle>
      <ProductBlockContainer>
        {products.map((product: Product) => {
          return (
            <ProductBlock
              key={product.sku}
              img={product.image}
              badges={[]}
              name={product.name}
              details={`From $${product.lowestPrice || product.price}`}
              href={`${getGatsbySiteUrl()}/${product.canonicalUrl}`}
              sku={product.sku}
            />
          );
        })}
      </ProductBlockContainer>
    </RelatedProductsContainer>
  );
}
