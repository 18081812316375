import { RelatedProduct } from 'types/Product';

type PartialProduct = {} & { relatedProducts: RelatedProduct[] }

export function selectRelatedProducts(product: PartialProduct) {
  return product.relatedProducts
    .filter(r => r.productRelationType === 'related')
    .sort((rA, rB) => rA.position - rB.position)
    .map(relatedProduct => relatedProduct.product);
}
