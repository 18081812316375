import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { numberToUSD } from 'helpers/currency';
import { ProductType } from '../types/event';
import { UseShippingRates } from 'hooks/UseShippingRates';
import { getEstimatedArrivalDateRange, isProductIneligibleForStandardShipping } from 'helpers/shipping';
import { STANDARD_SHIPPING_METHOD_NAME } from 'store/ui/constants';
import { isSpreadBook } from 'helpers/products';
import { SKU_GALLERY_WALL_DESIGN_SERVICE } from 'component-library/constants/products';

const BOOKSIZE_SWATCH = 'booksize_swatch';

type AccordionType = {
  id?: string;
  title?: string;
  content?: string | ReactElement | ProductType[keyof ProductType];
  isHtmlString?: boolean;
};

const AccordionContentTable = styled.table`
  font-family: Lato, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;

const AccordionContentTableHeader = styled.th`
  background-color: #f0f0f0;
  text-align: left;
  padding: 8px;
`;

const AccordionContentTableValueHeader = styled(AccordionContentTableHeader)`
  text-align: right;
`;

const AccordionContentTableKeyRow = styled.td`
  padding: 8px;
  text-align: left;
`;

const AccordionContentTableValueRow = styled.td`
  padding: 8px;
  text-align: right;
`;

const ItalicTextBlock = styled.div<{ marginTop?: number, textAlign?: string }>`
  font-style: italic;
  text-align: ${props => props.textAlign ?? 'center'};
  margin-top: ${props => `${props.marginTop ?? 0.5}rem;`}
`;

const AdditionalPriceInfoLink = styled.a`
  text-align: center;
  display: block;
  margin-top: 1rem;
`;

const buildData = (product: ProductType, key: keyof ProductType, title: string, id: string) => {
  return product[key] ? [{ id, title, content: product[key], isHtmlString: true }] : [];
};

const buildAccordionTable = (titleName: string, titleValue: string, tableRows: Record<string, ReactNode>) => {
  return (
    <AccordionContentTable>
      <thead>
        <tr>
          <AccordionContentTableHeader>{titleName}</AccordionContentTableHeader>
          <AccordionContentTableValueHeader>{titleValue}</AccordionContentTableValueHeader>
        </tr>
      </thead>
      <tbody>
        {tableRows &&
          Object.entries(tableRows).length &&
          Object.entries(tableRows).map(([key, value], index) => (
            <tr key={key} style={index % 2 ? { backgroundColor: '#F5F7F8' } : {}}>
              <AccordionContentTableKeyRow>{key}</AccordionContentTableKeyRow>
              <AccordionContentTableValueRow>{value}</AccordionContentTableValueRow>
            </tr>
          ))}
      </tbody>
    </AccordionContentTable>
  );
};

const getProductPagePricingData = (product: ProductType) => {
  const bookSizeOptionName = product?.customOptions?.find(option => option.react_pdp_input_type === BOOKSIZE_SWATCH)
    ?.title;
  if (!bookSizeOptionName) {
    return {};
  }

  let pricingData = product.pricingDetailsHtml ? product.pricingDetailsHtml[bookSizeOptionName] : null;
  if (!pricingData) {
    return {};
  }

  const bookSizeOption = product?.customOptions?.find(option => option.react_pdp_input_type === BOOKSIZE_SWATCH);
  const visibleSizes = bookSizeOption.values.map(albumSize => {
    if (albumSize.visible_on_pdp === '1') {
      return albumSize.title;
    } else {
      return null;
    }
  });

  //Remove any sizes that are not visible on the pdp
  for (const size in pricingData) {
    if (!visibleSizes.includes(size)) {
      delete pricingData[size];
    }
  }

  pricingData = Object.keys(pricingData).reduce((acc, key) => {
    const formattedKey = key.replace('x', ' x ') + ' inches';

    //Halving displayed price if the product is a spread book because users were confused when the price was for a spread instead or per page
    acc[formattedKey] = numberToUSD(isSpreadBook(product?.hammerCategory) ? pricingData[key] / 2 : pricingData[key]);
    return acc;
  }, {});

  return pricingData;
};

const buildPricingData = (
  product: ProductType,
  _key: keyof ProductType,
  title: string,
  id: string
): AccordionType[] => {
  const pricingData = getProductPagePricingData(product);
  if (!Object.keys(pricingData).length) {
    return [];
  }

  const priceInfoUrl = 'https://help.artifactuprising.com/hc/en-us/articles/360011531631-Are-All-Prices-Shown-in-USD-';
  const content = (
    <div>
      {buildAccordionTable('Size', 'Page Cost', pricingData)}
      {product.pagePricingDetails ? <ItalicTextBlock>{product.pagePricingDetails}</ItalicTextBlock> : null}
      <AdditionalPriceInfoLink href={priceInfoUrl} target="_blank">
        View Additional Pricing Information
      </AdditionalPriceInfoLink>
    </div>
  );

  return [{ id, title, content: content, isHtmlString: false }];
};

const getProductShippingData = (product: ProductType) => {
  const formattedShippingRates = {};

  let shippingRates = UseShippingRates();

  // Only show standard shipping if the product is eligible
  if (isProductIneligibleForStandardShipping(product.sku, product.reportingProductCategory)) {
    shippingRates = shippingRates.filter(rate => rate.name !== STANDARD_SHIPPING_METHOD_NAME);
  }

  shippingRates = shippingRates.filter(rate => !rate['name'].includes('International'));

  shippingRates.forEach(rate => {
    formattedShippingRates[rate['name']] = getEstimatedArrivalDateRange(
      parseInt(rate['min_shipping_time']),
      parseInt(rate['max_shipping_time']),
      parseInt(product.sla)
    );
  });

  return formattedShippingRates;
};

const buildShippingData = (
  product: ProductType,
  _key: keyof ProductType,
  title: string,
  id: string
): AccordionType[] => {
  const shippingData = getProductShippingData(product);
  if (!Object.keys(shippingData).length) {
    return [];
  }

  const isGalleryWallDS = product.sku === SKU_GALLERY_WALL_DESIGN_SERVICE;

  const defaultContent = (
    <>
      {buildAccordionTable('Shipping', 'Estimated Arrival', shippingData)}
      <ItalicTextBlock marginTop={1}>Special conditions apply for HI, AK, PR, and international orders</ItalicTextBlock>
      <ItalicTextBlock>Estimated Arrival includes production time of {product.sla} business days</ItalicTextBlock>
    </>
  );

  const shippingFaqUrl = 'https://help.artifactuprising.com/hc/en-us/articles/360000036051-Production-Shipping-Time';
  const galleryWallDSContent = (
    <>
      <ItalicTextBlock textAlign="start" marginTop={0}>Once you approve your design and purchase your frames, production
        time is 5 business days. The total shipping time varies based on the method you choose.</ItalicTextBlock>
      <ItalicTextBlock textAlign="start" marginTop={0.75}>Shipping cost will be calculated at the time of checkout with
        your frames.</ItalicTextBlock>
      <ItalicTextBlock textAlign="start" marginTop={0.75}><a href={shippingFaqUrl} target="_blank" rel="noreferrer">View
        Shipping FAQ &gt;</a></ItalicTextBlock>
    </>
  );

  const shippingAlertBlock = product.shippingAlert || '';
  const shippingAndReturnInfoBlock = product.shippingInformation ? product.shippingInformation : '';

  const content = (
    <div>
      <div dangerouslySetInnerHTML={{ __html: shippingAlertBlock }} id={'accordion-content-shipping-alert'} />

      {isGalleryWallDS ? galleryWallDSContent : defaultContent}

      <div
        dangerouslySetInnerHTML={{ __html: shippingAndReturnInfoBlock }}
        id={'accordion-content-shipping-return-info'}
      />
    </div>
  );

  return [{ id, title, content: content, isHtmlString: false }];
};

export const getAccordionData = (product: ProductType, displayNewAccordions: boolean): AccordionType[] => {
  if (displayNewAccordions) {
    const productFeaturesTitle = 'Product Features';
    const shippingTitle = 'Shipping & Returns';
    const pricingDetailsTitle = 'Pricing Details';
    const faqTitle = 'FAQs';
    const additionalDetailsTitle = 'Additional Details';

    const contentToPrependToFeatures = product.descriptionAuDifference ?? product.description;

    const productFeaturesContent = contentToPrependToFeatures ? contentToPrependToFeatures + '</br></br>' : '';

    const accordions = [
      {
        id: 'productFeatures',
        title: productFeaturesTitle,
        content: productFeaturesContent,
        isHtmlString: true
      },
      ...buildShippingData(product, 'shippingInfoHtml', shippingTitle, 'shipping'),
      ...buildPricingData(product, 'pricingDetailsHtml', pricingDetailsTitle, 'pricing'),
      ...buildData(product, 'FAQHtml', faqTitle, 'faq'),
      ...buildData(product, 'detailsHtml', additionalDetailsTitle, 'details')
    ];

    return accordions;
  }

  const productDetailsTitle = 'Product Details';
  const shippingTitle = product.accordionTitleShippingTime;
  const extraTitle = product.productDetailsExtraLabel;

  const accordions = [
    ...buildData(product, 'shippingInfoHtml', shippingTitle, 'shipping'),
    ...buildData(product, 'detailsHtml', productDetailsTitle, 'productFeatures'),
    ...buildData(product, 'productDetailsExtraContent', extraTitle, 'extra')
  ];

  return accordions;
};
