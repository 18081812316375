/**
 * This component has been extracted from the component-library.
 * TODO: Eventually we should consider rolling these changes back upstream.
 */
import React from 'react';
import styled from 'styled-components';
import { mediaQuery, Badge, CatalogImage } from 'component-library';

import { StyledP } from './typography';
import { trackAssetClicked } from 'analytics/itly/hooks';
import { CATALOG_IMAGE_RESPONSIVE_SIZES } from 'constants/images';

interface ProductBlockProps {
  img: string
  name: string
  alt?: string
  details?: string
  href: string
  badges?: string[]
}

const ProductBlockWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
`;

const ProductBadge = styled.div`
  z-index: 1;
  position: absolute;
  padding: 12px;
  ${mediaQuery.medium`
    padding: 16px;
 `};
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  ${StyledP} {
    text-align: center;
    margin: 2px 0;
  }
`;

export default function ProductBlock(props: ProductBlockProps) {
  const { href, img, name, badges, ...optionalProps } = props;
  const details = optionalProps.details || name;
  const alt = optionalProps.alt || details;

  // In case of multiple badges, just grab the first badge in the list.
  const badge = badges && badges.length > 0 && badges[0];
  return (
    <ProductBlockWrapper>
      {badge && (
        <ProductBadge>
          <Badge>{badge}</Badge>
        </ProductBadge>
     )}
      <a onClick={()=> trackAssetClicked(name || alt, 'ProductBlock', href)} href={href}>
        <CatalogImage
          src={img}
          alt={alt}
          widths={CATALOG_IMAGE_RESPONSIVE_SIZES}
        />
      </a>
      <ProductDetailsWrapper>
        <a onClick={()=> trackAssetClicked(name || alt, 'ProductBlock', href)} href={href}>
          <StyledP>{name}</StyledP>
        </a>
        <StyledP>{details}</StyledP>
      </ProductDetailsWrapper>
    </ProductBlockWrapper>
  );
}
