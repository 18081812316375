/**
 * This component has been extracted from the component-library to support
 * ProductBlock.
 * TODO: Eventually we should consider rolling these changes back upstream.
 */
import styled, { css } from 'styled-components';

export interface TypographyProps {
  color?: string
  fontFamily?: string
  lightBlue?: boolean
  alignment?: 'left' | 'center' | 'right'
  fontStyle?: 'italic' | 'bold'
}

export const baseStyles = css`
  color: ${props => (props.color ? props.color : '#000')};
  font-weight: normal;
  line-height: 1.0;

  /* Font Colors */
  ${({ lightBlue }: TypographyProps) =>
    lightBlue &&
    css`
      color: #629eba;
    `}

  /* Text Align */
  ${({ alignment }: TypographyProps) =>
    alignment &&
    css`
      text-align: ${alignment};
    `}

  /* Font Style */
  ${({ fontStyle }: TypographyProps) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `}
`;

export const StyledH3 = styled.h3`
  ${baseStyles};
  font-size: 1.375rem;
  line-height: 2rem;
`;

export const StyledP = styled.p`
  ${baseStyles};
  font-size: 1rem;
  line-height: 1.35rem;
`;
