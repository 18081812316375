import QuoteDataCartItem from 'au-js-sdk/lib/api/models/magento/quote/QuoteDataCartItem';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import {
  getShippingTotalFromTotalSegments,
  getSubtotalWithPromoApplied,
  getTotalSegment
} from 'store/cart/magentoSelectors';
import { FREE_SHIPPING_METHOD_NAME, STANDARD_SHIPPING_METHOD_NAME } from 'store/ui/constants';
import { isEligibleForStandardShipping } from '.';

export const FREE_SHIPPING_THRESHOLD = 199;

export const shouldShowFreeShippingText = (
  cartItems: QuoteDataCartItem[],
  totalSegments: QuoteDataTotalSegment[]
): boolean => {
  // If we've already passed the $FREE_SHIPPING_THRESHOLD threshhold for free shipping, don't show the message.
  if (FREE_SHIPPING_THRESHOLD - getSubtotalWithPromoApplied(totalSegments) <= 0) {
    return false;
  }
  if (!isEligibleForStandardShipping(cartItems)) {
    return false;
  }
  return true;
};

export const meetsFreeShippingRequirements = (
  cartItems: QuoteDataCartItem[],
  totalSegments: QuoteDataTotalSegment[]
): boolean => {
  // If haven't passed the $FREE_SHIPPING_THRESHOLD threshhold for free shipping, don't show the message.
  if (FREE_SHIPPING_THRESHOLD - getSubtotalWithPromoApplied(totalSegments) > 0) {
    return false;
  }
  if (!isEligibleForStandardShipping(cartItems)) {
    return false;
  }
  return true;
};

export const isFreeShippingSelected = (totalSegments: QuoteDataTotalSegment[]): boolean => {
  return (
    getTotalSegment('shipping')(totalSegments).title &&
    getTotalSegment('shipping')(totalSegments).title.includes(FREE_SHIPPING_METHOD_NAME)
  );
};

const shouldShowPrice = (totalSegments): boolean => FREE_SHIPPING_THRESHOLD <= getSubtotalWithPromoApplied(totalSegments);

export const getShippingCost = (
  hasShippingTotalFromCart: boolean,
  totalSegments: QuoteDataTotalSegment[],
  firstRate: QuoteDataShippingMethod,
  isVirtualCart: boolean
): string => {
  if (isVirtualCart) {
    return '';
  }
  if (hasShippingTotalFromCart && !firstRate) {
    return getShippingTotalFromTotalSegments(totalSegments) > 0
      ? getShippingTotalFromTotalSegments(totalSegments).toString()
      : 'FREE';
  }
  if (firstRate) {
    return shouldShowPrice(totalSegments) || firstRate.amount === 0
      ? 'FREE'
      : firstRate.amount.toString();
  }
  if (shouldShowPrice(totalSegments)) {
    return 'FREE';
  }
  return '―';
};

export const filterShippingRatesForFreeShipping = (
  totalSegments: QuoteDataTotalSegment[],
  shippingRate: QuoteDataShippingMethod,
): boolean => {
  // If our subtotal is less than $FREE_SHIPPING_THRESHOLD, we aren't allowing the free version no matter what
  if (
    getSubtotalWithPromoApplied(totalSegments) < FREE_SHIPPING_THRESHOLD &&
    shippingRate.method_title.trim() === FREE_SHIPPING_METHOD_NAME
  ) {
    return false;
  }
  if (
    getSubtotalWithPromoApplied(totalSegments) < FREE_SHIPPING_THRESHOLD &&
    shippingRate.method_title.trim() === STANDARD_SHIPPING_METHOD_NAME
  ) {
    return true;
  }

  // EN-7744 leaving this in place for the event we revist free shipping
  // If we are doing free standard shipping, don't show the paid version
  /* if (shippingRate.method_title.trim() === STANDARD_SHIPPING_METHOD_NAME) {
    return false;
  } */
  // If we aren't doing free standard shipping, don't show the free version
/*   if (shippingRate.method_title.trim() === FREE_SHIPPING_METHOD_NAME) {
    return false;
  } */
  return true;
};
