import { apiRequest, HTTP_DELETE, HTTP_GET, HTTP_PATCH, HTTP_POST } from 'helpers/http';
import { ContactGroup } from 'au-js-sdk/lib/models/ContactGroup';
import { Address, AddressData } from 'au-js-sdk/lib/models/Address';
import { checkSessionStorageExpiration, getSessionStorage, setSessionStorage } from 'helpers/storage';
import { ADDRESS_LIST_KEY, CONTACT_GROUPS_KEY } from 'constants/storage';

const CONTACT_GROUPS = 'contact-groups';

const addressServiceRequest = apiRequest(process.env.GATSBY_CRUD_CORE_BASE_URL, false);

export const deleteContacts = async (
  deletedContacts: string[],
  flashId: string,
  flashToken: string
): Promise<Address[]> => {
  const responses = [];
  const storedContacts = getSessionStorage(flashId, ADDRESS_LIST_KEY);
  const filteredContacts = storedContacts.data.filter(contact => !deletedContacts.includes(contact.id));

  buildAndSetSessionStorageObject(flashId, filteredContacts, ADDRESS_LIST_KEY);

  // although sequence doesn't matter here this should be refactored since async shouldn't/can't be
  // called in forEach
  // while youre at it you can probably make this and the below function DRY
  deletedContacts.forEach(async (contactGroup: string) => {
    const response = await addressServiceRequest(
      'api',
      'addresses',
      `?flashId=${flashId}&addressId=${contactGroup}`
    )(HTTP_DELETE)({
      Authorization: `Bearer ${flashToken}`
    })<Address[]>({});

    responses.push(response);
  });

  return filteredContacts;
};

export const deleteContactGroups = async (
  deletedContactGroups: string[],
  flashId: string,
  flashToken: string
): Promise<ContactGroup[]> => {
  const responses = [];
  const storedContactGroups = getSessionStorage(flashId, CONTACT_GROUPS_KEY);
  const filteredContactGroups = storedContactGroups.data.filter(
    contactGroup => !deletedContactGroups.includes(contactGroup.groupId)
  );

  buildAndSetSessionStorageObject(flashId, filteredContactGroups, CONTACT_GROUPS_KEY);

  // although sequence doesn't matter here this should be refactored since async shouldn't/can't be
  // called in forEach
  deletedContactGroups.forEach(async (contactGroup: string) => {
    const response = await addressServiceRequest(
      'api',
      CONTACT_GROUPS,
      `?flashId=${flashId}&groupId=${contactGroup}`
    )(HTTP_DELETE)({
      Authorization: `Bearer ${flashToken}`
    })<ContactGroup[]>({});

    responses.push(response);
  });

  return filteredContactGroups;
};

export const getContactGroups = async (flashId: string, flashToken: string): Promise<ContactGroup[]> => {
  const storedContactGroupData = getSessionStorage(flashId, CONTACT_GROUPS_KEY);

  if (!storedContactGroupData || !checkSessionStorageExpiration(storedContactGroupData.createdAt)) {
    // TODO: Whenever we need to make a an API call to updates the contact group
    //  lets move this into a function that can be re-used an optionally take a body probably: EN-7435
    const response = await addressServiceRequest('api', CONTACT_GROUPS)(HTTP_GET)({
      Authorization: `Bearer ${flashToken}`
    })<ContactGroup[]>({
      flashId
    });

    const hydratedContactGroupResponse = response
      .getOrElse([])
      .map((contactGroup: ContactGroup) => hydrateContactGroupType(contactGroup))
      .filter((contactGroup: ContactGroup) => !contactGroup.markedAsDeleted);

    buildAndSetSessionStorageObject(flashId, hydratedContactGroupResponse, CONTACT_GROUPS_KEY);

    return hydratedContactGroupResponse;
  }

  return storedContactGroupData.data.filter((contactGroup: ContactGroup) => !contactGroup.markedAsDeleted);
};

export const getAddressList = async (flashId: string, flashToken: string): Promise<Address[]> => {
  const storedAddressListData = getSessionStorage(flashId, ADDRESS_LIST_KEY);

  if (!storedAddressListData || !checkSessionStorageExpiration(storedAddressListData.createdAt)) {
    const response = await addressServiceRequest('api', 'addresses')(HTTP_GET)({
      Authorization: `Bearer ${flashToken}`
    })<Address[]>({
      flashId
    });

    const hydratedAddressListResponse = response
      .getOrElse([])
      .map((address: Address) => hydrateAddressType(address))
      .filter((address: Address) => {
        // @ts-ignore - lol, there is a typo in the sdk
        return !address.markedAsDelete;
      });

    buildAndSetSessionStorageObject(flashId, hydratedAddressListResponse, ADDRESS_LIST_KEY);

    return hydratedAddressListResponse;
  }

  return storedAddressListData.data;
};

export const updateAddress = async (
  flashId: string,
  flashToken: string,
  address: AddressData,
  id: string
): Promise<Address> => {
  const addresses = getSessionStorage(flashId, ADDRESS_LIST_KEY).data;
  let existingAddress: Address = addresses.find(address => address.id === id);
  if (!existingAddress) {
    console.error('Non-existent address!', id);
  }
  //TODO: Let's make the PATCH request an actual patch (partial update)
  existingAddress = {
    ...existingAddress,
    ...address
  };
  const response = await addressServiceRequest('api', 'addresses')(HTTP_PATCH)({
    Authorization: `Bearer ${flashToken}`
  })<Address>({
    flashId,
    addressId: id,
    update: existingAddress
  });
  const addressResponse = hydrateAddressType(response.value);
  replaceAddressInStorage(flashId, addressResponse);
  return addressResponse;
};

export const createAddress = async (
  flashId: string,
  flashToken: string,
  address: AddressData,
  groupId: string
): Promise<Address> => {
  const response = await addressServiceRequest('api', 'addresses')(HTTP_POST)({
    Authorization: `Bearer ${flashToken}`
  })<Address[]>({
    flashId,
    ...address,
    groupIds: [groupId]
  });
  const addressResponse = hydrateAddressType(response.value);
  appendToSessionStorageObject(flashId, [addressResponse], ADDRESS_LIST_KEY);
  return addressResponse;
};

const hydrateContactGroupType = (obj: any): ContactGroup => {
  const contactGroup: ContactGroup = {
    groupId: obj.groupId,
    createdBy: obj.createdBy,
    createdAt: obj.createdAt,
    lastModifiedAt: obj.lastModifiedAt,
    groupName: obj.groupName,
    markedAsDeleted: obj.markedAsDeleted
  };

  return contactGroup;
};

const hydrateAddresses = (obj: any): Array<Address> => {
  const addresses: Array<Address> = [];
  for (const address of obj) {
    addresses.push(hydrateAddressType(address));
  }
  return addresses;
};

const replaceAddressInStorage = (flashId: string, address: Address) => {
  const storedAddressListData = getSessionStorage(flashId, ADDRESS_LIST_KEY);
  if (Array.isArray(storedAddressListData.data)) {
    const addressIndex = storedAddressListData.data.findIndex(sessionAddress => sessionAddress.id === address.id);
    if (addressIndex !== -1) {
      storedAddressListData.data[addressIndex] = address;
    } else {
      console.error('Address not found', address);
    }
    buildAndSetSessionStorageObject(flashId, storedAddressListData.data, ADDRESS_LIST_KEY);
    return storedAddressListData;
  }
  console.error('Address list not set', storedAddressListData);
};

const hydrateAddressType = (obj: any): Address => {
  const address: Address = {
    id: obj.id,
    createdBy: obj.createdBy,
    createdAt: obj.createdAt,
    lastModifiedAt: obj.lastModifiedAt,
    name: obj.name,
    line1: obj.line1,
    line2: obj.line2,
    city: obj.city,
    region: obj.region,
    postalCode: obj.postalCode,
    country: obj.country,
    phone: obj.phone,
    // @TODO: see whats going on with this type - not liking marked as deleted
    //@ts-ignore
    markedAsDelete: obj.markedAsDeleted,
    groupIds: obj.groupIds
  };

  return address;
};

export const createContactGroup = async (
  flashId: string,
  flashToken: string,
  groupName: string
): Promise<ContactGroup> => {
  const response = await addressServiceRequest('api', CONTACT_GROUPS)(HTTP_POST)({
    Authorization: `Bearer ${flashToken}`
  })({
    flashId,
    groupName
  });

  const hydratedContactGroupResponse = hydrateContactGroupType(response.value);

  appendToSessionStorageObject(flashId, [hydratedContactGroupResponse], CONTACT_GROUPS);
  return hydratedContactGroupResponse;
};

export const updateContactGroup = async (
  flashId: string,
  flashToken: string,
  newGroupName: string,
  groupId: string
): Promise<ContactGroup> => {
  const response = await addressServiceRequest(
    'api',
    CONTACT_GROUPS,
    `?flashId=${flashId}&groupId=${groupId}`
  )(HTTP_PATCH)({
    Authorization: `Bearer ${flashToken}`
  })({
    groupName: newGroupName
  });

  const hydratedContactGroupResponse = hydrateContactGroupType(response.value);

  updateSessionStorageContactList(flashId, hydratedContactGroupResponse, CONTACT_GROUPS);
  return hydratedContactGroupResponse;
};

const buildAndSetSessionStorageObject = (flashId: string, data: any, storageItemKey: string) => {
  const createdAt = Math.round(new Date().getTime() / 1000);
  const contactGroupSessionStorageObject = {
    createdAt,
    data
  };

  setSessionStorage(flashId, storageItemKey, contactGroupSessionStorageObject);
};

const appendToSessionStorageObject = (flashId: string, data: Array<any>, storageItemKey: string) => {
  const existingStorage = getSessionStorage(flashId, storageItemKey);
  if (Array.isArray(existingStorage.data)) {
    data = [...data, ...existingStorage.data];
  }

  buildAndSetSessionStorageObject(flashId, data, storageItemKey);
};

const updateSessionStorageContactList = (flashId: string, contactGroup: ContactGroup, storageItemKey: string) => {
  const existingStorage = getSessionStorage(flashId, storageItemKey);

  const contactList = existingStorage.data.find(contactList => contactList.groupId === contactGroup.groupId);
  contactList.groupName = contactGroup.groupName;

  buildAndSetSessionStorageObject(flashId, existingStorage.data, storageItemKey);
};

export const uploadCsvText = async (
  flashId: string,
  flashToken: string,
  groupId: string,
  csvText: string
): Promise<Array<Address>> => {
  const response = await addressServiceRequest('api', CONTACT_GROUPS, groupId, 'addresses')(HTTP_POST)({
    Authorization: `Bearer ${flashToken}`
  })({
    flashId,
    csvText
  });

  const hydratedAddresses = hydrateAddresses(response.value);
  appendToSessionStorageObject(flashId, hydratedAddresses, ADDRESS_LIST_KEY);
  return hydratedAddresses;
};
