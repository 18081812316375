import React, { useMemo } from 'react';
import { TrackingProp } from 'react-tracking';
import { SEO } from 'components/SEO';
import Show from 'components/Functional/Show';
import OverlayComponents from './OverlayComponents';
import Header from 'components/Header';
import Footer from 'components/Footer';
import useAccessibe from 'hooks/useAccessibe';
import { useTikTok } from 'hooks/useTikTok';
import { Container, GlobalLayoutWrapper, PromoBanner } from './styled';
import { PromoBannerESIBlock } from 'components/PromoBannerESIBlock';
import { SegmentConsentBanner } from 'components/SegmentConsentBanner';
import { useSplitTreatment } from 'hooks/splits/useSplitTreatment';
interface GlobalLayoutProps {
  children: React.ReactNode;
  bottomContent?: React.ReactNode;
  contained?: boolean;
  displayPromoBanner?: boolean;
  displayFooter?: boolean;
  tracking?: TrackingProp;
  contentPage?: boolean;
  width?: string;
}

const GlobalLayout = ({
  children,
  displayPromoBanner = false,
  displayFooter = true,
  contained = true,
  bottomContent = null,
  tracking,
  contentPage = false,
  width
}: GlobalLayoutProps) => {
  useAccessibe();
  useTikTok();

  return useMemo(
    () => (
      <>
        <GlobalLayoutWrapper className="GlobalLayout">
          <SEO title={'Artifact Uprising | Premium Photo Printing for Your Stories'} />
          <Show when={displayPromoBanner}>
            <PromoBannerESIBlock />
          </Show>
          {contained ? (
            <div>
              <Header />
              <Container contentPage={contentPage} width={width}>
                {children}
              </Container>
              {bottomContent}
            </div>
          ) : (
            <>
              <Header />
              {children}
            </>
          )}
          {displayFooter && <Footer />}
        </GlobalLayoutWrapper>
        <OverlayComponents />
        <SegmentConsentBanner />
      </>
    ),
    [bottomContent, displayPromoBanner, children, contained, displayFooter]
  );
};
export default GlobalLayout;
