import styled from 'styled-components';
import { mediaQuery } from 'component-library';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  html,
  .photo-slider-column {
    overflow: auto;
  }

  ${mediaQuery.medium`
    flex-direction: row;

    > * {
      padding: 0 25px;
    }

    .photo-slider-column {
      position: sticky;
      height: 100%;
      top: 0px;
    }
  `}
`;
