import { CategorySLA } from 'types/Product';
import { HAMMER_CATEGORY_SPREAD_BOOKS } from 'helpers/projects/redirect';

export const getShipCutoffContentBlock = (categorySLA: CategorySLA) => {
  const slugifiedCategorySLA = categorySLA?.replace(/ /g, '_').toLowerCase();
  return slugifiedCategorySLA ? `ship_cutoff_${slugifiedCategorySLA}` : null;
};

export const isSpreadBook = (hammerCategory: string): boolean => {
  return !!hammerCategory?.includes(HAMMER_CATEGORY_SPREAD_BOOKS);
};