import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { MagentoMenuItem } from '../../../../types/Navigation';
import CollapsibleNavLink from './CollapsibleNavLink';
import { isNotEmpty } from 'helpers/arrays';
import { showIfOrElse } from 'helpers/conditionals';
import { optionGet } from 'faunctions';
import { customerIsLoggedInSelector } from 'store/customer/selectors';
import { connect } from 'react-redux';
import { GlobalState } from 'store/constants';
import MobileNavLink from './MobileNavLink';
import { openCustomerLoginModal, openCustomerRegisterModal } from 'store/modal/actions';
import styled from 'styled-components';
import { StyledP } from 'component-library';
import { getMagentoBaseUrl } from 'helpers/env';
import LogoutButton from 'components/LogoutButton';
import { MY_ACCOUNT } from 'constants/navigation';

interface SideMenuProps {
  menuItems: MagentoMenuItem[];
  isCustomerLoggedIn: boolean;
  toggleMenu: () => void;
  openLoginModal: (redirect) => void;
  openRegisterModal: () => void;
  clearStore: () => void;
  removeUserCookies: () => void;
}

interface SideMenuState {
  openPosition: number;
}

const StyledNavLink = styled.button`
  cursor: pointer;
  border: none;
  padding: 15px 0;
  font-size: 16px;
  background: none;
`;

const SideMenuText = styled(StyledP)`
  margin-bottom: 0.5rem;

  a {
    text-decoration: underline !important;
  }
`;

// TODO refactor as functional component
class SideMenu extends React.Component<SideMenuProps, SideMenuState> {
  constructor(props: SideMenuProps) {
    super(props);

    this.state = {
      openPosition: -1
    };

    this.openCollapsible = this.openCollapsible.bind(this);
    this.closeCollapsible = this.closeCollapsible.bind(this);
  }

  openCollapsible(pos: number) {
    this.setState({ openPosition: pos });
  }

  closeCollapsible() {
    this.setState({ openPosition: -1 });
  }

  getPath() {
    return window?.location?.href;
  }

  render() {
    const { menuItems, toggleMenu, isCustomerLoggedIn, openLoginModal, openRegisterModal } = this.props;
    const { openPosition } = this.state;

    return ReactDOM.createPortal(
      <Fragment>
        <div className="MobileNav__SideMenu">
          {/* <CatalogSearch /> @todo: Add a debounce to this onKeyPress */}
          <div className="SideMenu__NavLinks">
            <MobileNavLink
              key={'mobile-nav-link-home'}
              id={'mobile-nav-link-home'}
              name="Home"
              path="/"
              onClick={toggleMenu}
            />
            <MobileNavLink name="Help" id={'help-link'} path={`${process.env.GATSBY_HELP_URL}`} />

            {menuItems.map((mi: MagentoMenuItem, i: number) => {
              const miNode = optionGet('node')(mi).getOrElse([]);
              miNode.onClick = toggleMenu;
              const childrenMenuItems = optionGet('items')(miNode).getOrElse([]);

              if (isNotEmpty(childrenMenuItems)) {
                for (const item of miNode.items) {
                  item.onClick = toggleMenu;
                }
                return (
                  <CollapsibleNavLink
                    key={`collapsible-mobile-nav-link-${miNode && miNode.name}`}
                    id={`collapsible-mobile-nav-link-${miNode && miNode.name}`}
                    position={i}
                    isOpen={openPosition === i}
                    handleOpen={this.openCollapsible}
                    handleClose={this.closeCollapsible}
                    menuItem={miNode}
                  />
                );
              } else {
                return (
                  <MobileNavLink
                    key={`mobile-nav-link-${miNode.name}`}
                    id={`mobile-nav-link-${miNode.name}`}
                    name={miNode.name}
                    path={miNode.path}
                    onClick={toggleMenu}
                  />
                );
              }
            })}
          </div>
          {showIfOrElse(isCustomerLoggedIn)(
            <div>
              {/* Once we start to switch over to the new gatsby-ified account pages we will use this */}
              <MobileNavLink
                id={'mobile-nav-link-my-account'}
                key={'mobile-nav-link-my-account'}
                name="My Account"
                path={MY_ACCOUNT.url}
              />
              <MobileNavLink
                id={'mobile-nav-link-my-projects'}
                key={'mobile-nav-link-my-projects'}
                name="My Projects"
                path={`${getMagentoBaseUrl()}/account/projects`}
              />
              <LogoutButton className={'MobileNav__Link'} id={'mobile-nav-link-Logout'} />
            </div>
          )(
            <div>
              <StyledNavLink
                style={{ textTransform: 'uppercase', fontSize: '11px', letterSpacing: '1.5px', paddingTop: '20px' }}
                name="Login"
                onClick={() => openLoginModal(this.getPath())}
                id={'styled-nav-link-login'}
                key={'styled-nav-link-login'}
              >
                Login
              </StyledNavLink>
              <br />
              <StyledNavLink
                style={{ textTransform: 'uppercase', fontSize: '11px', letterSpacing: '1.5px', textDecoration: 'none' }}
              >
                <a onClick={() => openRegisterModal()}>Create An Account</a>
              </StyledNavLink>
            </div>
          )}
          <MobileNavLink
            id={'mobile-nav-link-download-app'}
            key={'mobile-nav-link-download-app'}
            name="Download the App"
            path="https://apps.apple.com/us/app/artifact-uprising/id713083894"
          />
        </div>
        <div className="MobileNav__SideMenu__Overlay" onClick={toggleMenu} />
      </Fragment>,
      document.body
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  isCustomerLoggedIn: customerIsLoggedInSelector(state)
});

const mapDispatchToProps = dispatch => ({
  openLoginModal: (redirect) => dispatch(openCustomerLoginModal({redirect: redirect})),
  openRegisterModal: () => dispatch(openCustomerRegisterModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
