import React, { Component, ErrorInfo } from 'react';
import { PrimaryButton } from 'component-library';
import styled from 'styled-components';
import { nrError } from 'helpers/reporting/newrelic';
import GlobalLayout from 'components/Layouts/GlobalLayout';
import { isServerSideRendering } from 'helpers/env';

export const ERROR_MESSAGE_GENERIC = 'Something went wrong, please try again.';

if (!isServerSideRendering()) {
  window.onerror = (errorEvent: Event | string, source: string | undefined, lineno: number | undefined, colno: number | undefined, error: Error | undefined) => {
    nrError('Uncaught window error', error, {
      errorEvent,
      source,
      lineno,
      colno,
      error
    });
  };
}

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 30px;
  margin-bottom: 32px;
`;

const ReloadButton = styled(PrimaryButton)`
  max-width: 250px;
  display: block;
  margin: auto;
`;

const reload = () => window.location.reload();

class ErrorBoundary extends Component {
  state = { hasError: false }
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromError(error, errorInfo) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, data: ErrorInfo) {
    nrError('Global ErrorBoundary', error, data);
  }

  render() {
    if (this.state.hasError) {
      return (
        <GlobalLayout>
          <ErrorMessage>
            <div>Whoops! Something went wrong on our end.</div>
            <div>Sorry about that!</div>
          </ErrorMessage>
          <ReloadButton onClick={reload}>Refresh the page</ReloadButton>
        </GlobalLayout>
      );
    }
    return this.props.children;
  }
}

export function withErrorBoundary({ element }) {
  return <ErrorBoundary>{element}</ErrorBoundary>;
}
