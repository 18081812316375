import { TrackingProp } from 'react-tracking';
import { useEffect } from 'react';
import { CartViewedEvent } from '../analyticsWrapper';
import { Cart__Patched } from '../../../pages/checkout/cart';
import { CartViewedProperties } from 'itly';
import { cartItemToAnalyticsProduct, createCartProperties, createShippingMethodProperties } from '../tracking';
import { useProductSLA } from '../../../hooks/useProductSLA';

export function useTrackCartViewed(tracking: TrackingProp<CartViewedEvent>, cart: Cart__Patched, ...deps: any[]) {
  const numCartItems = cart.items && cart.items.length ? cart.items.length : 0;
  const { getLongestSLAFromCart } = useProductSLA();
  const longestSLA = getLongestSLAFromCart(cart);
  const dependencies = [cart.entity_id, numCartItems, cart, longestSLA, tracking, ...deps];

  useEffect(() => trackCartViewed(tracking, cart, longestSLA), dependencies);
}

function trackCartViewed(tracking: TrackingProp<CartViewedEvent>, cart: Cart__Patched, longestSLA = null) {
  if (cart.entity_id !== undefined && cart.items !== undefined) {
    const products = cart.items.map(cartItemToAnalyticsProduct);
    const shippingProperties = createShippingMethodProperties(cart, longestSLA);
    const cartProperties = createCartProperties(cart);
    const data: CartViewedProperties = {
      cart_id: cart.entity_id,
      products, ...cartProperties,
      shipping_properties: shippingProperties
    };

    tracking.trackEvent({ type: 'cartViewed', data });
  }
}
