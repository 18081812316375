import { useCallback } from 'react';
import { PaginatedResponse } from 'au-js-sdk/lib/api/models/PaginatedResponse';
import { CompactProject } from 'au-js-sdk/lib/api/models/GetProjectResponse';
import { Project } from 'au-js-sdk/lib/models/Project';
import { HydratedProjectV1, ProjectRequestResponse } from '../../types/projects';
import { Resource } from 'au-js-sdk/lib/types/relatives';
import { DuplicateProjectResponse } from 'au-js-sdk/lib/api/models/cc/project/DuplicateProjectResponse';
import { useCrudCoreProjectsRequest } from './useCrudCoreProjectsRequest';
import { hydrateProjectType, useProjectServiceRequest } from './useProjectServiceRequest';
import { GetAllProjectsRequest } from 'au-js-sdk/lib/api/requests/projects/getAllProjects';

export type UseProjectsRequestProps = GetAllProjectsRequest & {
  url?: string;
};

export const useProjectsRequest = (config: UseProjectsRequestProps) => {
  const projectServiceRequest = useProjectServiceRequest(config);
  const crudCoreProjectsRequest = useCrudCoreProjectsRequest(config);

  const fetchProjects = useCallback(
    async (params: GetAllProjectsRequest): Promise<PaginatedResponse<CompactProject>> =>
      crudCoreProjectsRequest.fetchProjects(params),
    [config]
  );

  const fetchProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<Project | HydratedProjectV1 | Resource> => {
      if (schemaVersion === '1') {
        return projectServiceRequest.fetchProject(projectId);
      }

      return crudCoreProjectsRequest.fetchProject(projectId);
    },
    [config]
  );

  const deleteProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<boolean | string> => {
      if (schemaVersion === '1') {
        return projectServiceRequest.deleteProject(projectId);
      }

      return crudCoreProjectsRequest.deleteProject(projectId);
    },
    [config]
  );

  const duplicateProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<DuplicateProjectResponse> => {
      if (schemaVersion === '1') {
        return projectServiceRequest.duplicateProject(projectId);
      }

      return crudCoreProjectsRequest.duplicateProject(projectId);
    },
    [config]
  );

  const restoreProject = useCallback(
    async (projectId: string, schemaVersion?: '1' | '2'): Promise<void | Project | string> => {
      if (schemaVersion === '1') {
        return projectServiceRequest.restoreProject(projectId);
      }

      return crudCoreProjectsRequest.restoreProject(projectId);
    },
    [config]
  );

  const updateProjectStatus = useCallback(
    async (projectId: string, status, schemaVersion?: '1' | '2'): Promise<void | Project | string> => {
      if (schemaVersion === '1') {
        return projectServiceRequest.updateProjectStatus(projectId, status);
      }

      return crudCoreProjectsRequest.updateProjectStatus(projectId, status);
    },
    [config]
  );

  const updateProjectName = useCallback(
    async (projectId: string, newName: string, schemaVersion?: '1' | '2'): Promise<Project | HydratedProjectV1> => {
      if (schemaVersion === '1') {
        const fetchRawResponse: ProjectRequestResponse = await projectServiceRequest.fetchRawProject(projectId);
        const projectData = JSON.parse(fetchRawResponse?.projectData || '{}');

        if (projectData.data?.project?.name) {
          projectData.data.project.name = newName;
          const updateResponse = await projectServiceRequest.updateProject(projectId, projectData, true);

          const updatedProject: ProjectRequestResponse = {
            ...updateResponse,
            projectData: JSON.stringify(projectData)
          };
          return hydrateProjectType(updatedProject);
        }
        return;
      }

      return crudCoreProjectsRequest.updateProjectName(projectId, newName);
    },
    [config]
  );

  return {
    fetchProject,
    fetchProjects,
    deleteProject,
    duplicateProject,
    restoreProject,
    updateProjectStatus,
    updateProjectName
  };
};
