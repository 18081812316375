import React from 'react';
import { Product, RelatedProduct } from 'types/Product';
import styled from 'styled-components';
import { mediaQuery } from 'component-library';

import YotpoReviews from 'components/Yotpo/YotpoReviews';
import BuyBanner from 'components/BuyBanner';
import SeoBlock from 'components/SeoBlock';
import { selectRelatedProducts, RelatedProducts } from 'components/RelatedProducts';
import { selectUpsellProducts, Upsell } from 'components/Upsell';
import Breadcrumbs from 'components/Breadcrumbs';
import { getYotpoApiKey } from '../../../helpers/env';

const MobileBreadcrumbs = styled(Breadcrumbs)`
  ${mediaQuery.medium`
    display: none;
`};
`;

interface BottomContentProps {
  breadcrumbs: [{ name: string; urlPath: string }];
  product: Product & {
    relatedProducts: RelatedProduct[];
    bannerImage?: string;
    seoBlock?: string;
  };
  shouldHideBuyBanner?: boolean;
}

export function BottomContent(props: BottomContentProps): JSX.Element {
  const { breadcrumbs, product, shouldHideBuyBanner } = props;
  const relatedProducts = selectRelatedProducts(product);
  const upsellProducts = selectUpsellProducts(product);
  return (
    <>
      {shouldHideBuyBanner && <BuyBanner className="secondary" />}
      <MarketingBanner product={product} />
      <RelatedProducts products={relatedProducts} />
      <Upsell products={upsellProducts} />
      <PDPSeoBlock product={product} />
      <YotpoReviews apiKey={getYotpoApiKey()} productId={`${product.productId}`} productName={product.name} />
      <MobileBreadcrumbs pages={breadcrumbs} />
    </>
  );
}

interface PDPSeoBlockProps {
  product: {} & {
    seoBlock?: string;
  };
}

function PDPSeoBlock(props: PDPSeoBlockProps) {
  const { product } = props;
  if (!product.seoBlock) {
    return null;
  }
  return (
    <SeoBlock
      content={product.seoBlock}
      css={`
        max-width: 1180px;
        padding: 1rem;
        margin: auto;
        display: none;
        ${mediaQuery.medium`
            display: block;
        `};
      `}
    />
  );
}

const MarketingBannerContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1180px;
  flex-direction: column;
  margin: auto;

  > div {
    width: 100%;
    > iframe {
      width: 100%;
    }
  }

  .collection__testimonials,
  .header__testimonials {
    display: none;
  }

  .embed-container {
    margin-top: 50px;
  }

  .header__estimator {
    font-size: 1.375rem;
    line-height: 2rem;
    font-family: 'Crimson Text', 'Georgia', serif;
    font-weight: normal;
    line-height: 1;
  }
`;

interface MarketingBannerProps {
  product: {} & {
    bannerImage?: string;
  };
}

function MarketingBanner(props: MarketingBannerProps) {
  const { product } = props;
  if (!product.bannerImage) {
    return null;
  }
  return <MarketingBannerContainer dangerouslySetInnerHTML={{ __html: product.bannerImage }} />;
}
