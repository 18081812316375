import React from 'react';
import { Column } from 'component-library';
import { useProduct } from '../hooks/useProduct';
import { useProductLivePreview } from '../hooks/useProductLivePreview';
import { useProductPrice } from '../hooks/useProductPrice';
import { useMedia } from '../hooks/useMedia';
import { PDPForm } from './Form';
import { getGatsbySiteUrl } from 'helpers/env';
import { Wrapper } from './styled';
import { Rating } from 'types/Product';
import PhotoSliderV2 from 'components/PhotoSliderV2';
import { useStickyBox } from 'react-sticky-box';
import ProductHeader from './ProductHeader';
import { PdpAccordion } from './PdpAccordion';
import { useProductUseCase } from '../hooks/useProductUseCase';
import { generateLivePreviewImage } from '../helpers';
import ProductHeaderNewDesign from './ProductHeaderNewDesign';
import Show from 'component-library/Show';
import { useSplitTreatment } from '../../../hooks/splits/useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE,
  SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE_COOKIE
} from 'constants/split';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';
import { DeviceSize, useWindowSize } from '../../../hooks/useWindowSize';

type PDPProps = {
  yotpoStars: Rating;
  displayState: string;
};

// TODO: Once the split is at 100% we will handle this in a more data-driven way
const productsToRetainTopDetails = ['curation-services'];
export const pdpTopException = (sku: string): boolean => productsToRetainTopDetails.includes(sku);

export const PDP = ({ yotpoStars, displayState }: PDPProps): JSX.Element => {
  const { product } = useProduct();
  const { photos } = useMedia();
  const price = useProductPrice();
  const livePreviewUrl = useProductLivePreview();
  const useCaseValues = useProductUseCase();
  const { deviceSize } = useWindowSize();

  const isMobile = deviceSize === DeviceSize.SMALL;

  const { treatmentStatus: showProductInfoHierarchyMobileSplit } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE,
    SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );

  const showProductInfoHierarchyTest = showProductInfoHierarchyMobileSplit && isMobile && product.sku !== 'email-giftcard';

  // This is the photos list without the live preview image.  We are using this to prevent
  // re-rendering of the image carousel when live preview images change.
  const mobilePhotos = [...photos];
  if (livePreviewUrl) {
    const livePreviewImage = generateLivePreviewImage(livePreviewUrl);
    photos.unshift(livePreviewImage);
  }
  const stickyBoxRef = useStickyBox();

  let hideYotpoReview = false;
  if (yotpoStars === undefined || (yotpoStars.totalReviews < 5 && yotpoStars.score < 4)) {
    hideYotpoReview = true;
  }

  return (
    <div className="PdpApp">
      <Wrapper>
        <Column size={12} medium={8} className={'z-pt-tiny photo-slider-column'}>
          <PhotoSliderV2 photos={photos} mobilePhotos={mobilePhotos} livePreviewUrl={livePreviewUrl}
                         badges={product?.badges} />
        </Column>
        <Column size={12} medium={4} style={{ paddingBottom: '100px' }}>
          <div ref={stickyBoxRef}>
            <Show when={true}>
              {showProductInfoHierarchyTest ? (
                <ProductHeaderNewDesign
                  productTitle={useCaseValues.title}
                  price={price}
                  product={product}
                  siteUrl={getGatsbySiteUrl() || ''}
                  yotpoStars={!hideYotpoReview && yotpoStars}
                />
              ) : (
                <ProductHeader
                  productTitle={useCaseValues.title}
                  price={price}
                  product={product}
                  siteUrl={getGatsbySiteUrl() || ''}
                  yotpoStars={!hideYotpoReview && yotpoStars}
                />
              )}
            </Show>
            <PDPForm displayNewPdpTop displayState={displayState} />

            <PdpAccordion />
          </div>
        </Column>
      </Wrapper>
    </div>
  );
};
